import React, { useEffect, useState } from "react";
import { Button, Checkbox, Input, Modal, Text } from "@nextui-org/react";
import StyledSelect from "../../styles/styles";

const fieldOptions = [
  { label: "Text", value: 3 },
  { label: "Integer", value: 1 },
  { label: "Float", value: 2 },
  { label: "Image", value: 6 },
  { label: "Video", value: 4 },
  { label: "Audio", value: 5 },
  { label: "Boolean", value: 0 },
  { label: "File URL", value: 8 },
  // { label: "FlyMyAI S3 URL", value: 9 },
];

const FieldEditorModal = ({ isOpen, onClose, selectedHandle, onSave }) => {
  const [fieldType, setFieldType] = useState(selectedHandle?.field_type || 3);
  const [attributes, setAttributes] = useState(selectedHandle?.data || {});

  useEffect(() => {
    if (selectedHandle) {
      setFieldType(selectedHandle.field_type);
      setAttributes(selectedHandle.data || {});
    }
  }, [selectedHandle]);

  const handleAttributeChange = (name, value) => {
    setAttributes((prev) => ({ ...prev, [name]: value }));
  };

  const handleKeyPress = (e) => {
    if (e.key === ",") {
      e.preventDefault();
    }
  };

  const renderAttributeFields = () => {
    switch (fieldType) {
      case "0": // Boolean
      case 0:
        return (
          <>
            <Checkbox
              size="sm"
              isSelected={attributes.optional || false}
              onChange={(e) => handleAttributeChange("optional", e)}
            >
              Optional
            </Checkbox>
            <Checkbox
              size="sm"
              isSelected={attributes.default || false}
              onChange={(e) => handleAttributeChange("default", e)}
            >
              Default
            </Checkbox>
          </>
        );
      case "1": // Integer
      case 1:
        return (
          <>
            <Checkbox
              size="sm"
              isSelected={attributes.optional || false}
              onChange={(e) => handleAttributeChange("optional", e)}
            >
              Optional
            </Checkbox>
            <Input
              label="Minimum"
              type={"integer"}
              onKeyDown={handleKeyPress}
              value={attributes.minimum || null}
              onChange={(e) =>
                handleAttributeChange("minimum", parseInt(e.target.value))
              }
            />
            <Input
              label="Maximum"
              type={"integer"}
              onKeyDown={handleKeyPress}
              value={attributes.maximum || null}
              onChange={(e) =>
                handleAttributeChange("maximum", parseInt(e.target.value))
              }
            />
            <Input
              label="Default"
              type={"integer"}
              onKeyDown={handleKeyPress}
              value={attributes.default || null}
              onChange={(e) =>
                handleAttributeChange("default", parseInt(e.target.value))
              }
            />
          </>
        );
      case "2": // Float
      case 2:
        return (
          <>
            <Checkbox
              size="sm"
              isSelected={attributes.optional || false}
              onChange={(e) => handleAttributeChange("optional", e)}
            >
              Optional
            </Checkbox>
            <Input
              label="Minimum"
              type={"number"}
              onKeyDown={handleKeyPress}
              value={attributes.minimum || null}
              onChange={(e) => handleAttributeChange("minimum", e.target.value)}
            />
            <Input
              label="Maximum"
              type={"number"}
              onKeyDown={handleKeyPress}
              value={attributes.maximum || null}
              onChange={(e) => handleAttributeChange("maximum", e.target.value)}
            />
            <Input
              label="Default"
              type={"number"}
              onKeyDown={handleKeyPress}
              value={attributes.default || null}
              onChange={(e) => handleAttributeChange("default", e.target.value)}
            />
          </>
        );
      case "3": // Text
      case 3:
        return (
          <>
            <Checkbox
              size="sm"
              isSelected={attributes.optional || false}
              onChange={(e) => handleAttributeChange("optional", e)}
            >
              Optional
            </Checkbox>
            <Input
              label="Default"
              value={attributes.default || null}
              onChange={(e) => handleAttributeChange("default", e.target.value)}
            />
            <Input
              label="Max Length (empty for unlimited)"
              value={attributes.maxLength || null}
              onChange={(e) =>
                handleAttributeChange("maxLength", parseInt(e.target.value))
              }
            />
          </>
        );
      default:
        return (
          <Checkbox
            size="sm"
            isSelected={attributes.optional || false}
            onChange={(e) => handleAttributeChange("optional", e)}
          >
            Optional
          </Checkbox>
        );
    }
  };

  const saveField = () => {
    onSave(selectedHandle, {
      field_type: fieldType,
      attributes: attributes,
    });
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <Modal.Header>
        <Text h4>Edit {selectedHandle?.label_slug}</Text>
      </Modal.Header>
      <Modal.Body>
        <StyledSelect
          clearable
          value={fieldType}
          onChange={(e) => setFieldType(e.target.value)}
        >
          {fieldOptions.map((option) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </StyledSelect>
        {renderAttributeFields()}
      </Modal.Body>
      <Modal.Footer>
        <Button
          flat
          color="error"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button onClick={saveField}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FieldEditorModal;
