import user from './user';
import auth from './auth';
import clouds from './clouds';
import {combineReducers} from 'redux';

const rootReducer = combineReducers(
    {
        user: user,
        auth: auth,
        clouds: clouds,
        loadingUser: true,
    }
);

export default rootReducer;
