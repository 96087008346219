import React, { useEffect, useState } from "react";

import styles from "./NavBar.module.css";
import SvgIconPlus from "./images/SvgIconPlus";
import { useResize } from "../../../hooks/useresize.ts";

export const Wallet: React.FC<Balance> = ({ wallet }) => {
  const [balance, setBalance] = useState(wallet);
  let width = useResize();

  useEffect(() => {
    const walletFloat = parseFloat(wallet);
    if (walletFloat > 1000 && width < 450) {
      setBalance(`${(walletFloat / 1000).toFixed(0)} k`);
    } else {
      setBalance(parseFloat(wallet).toFixed(2));
    }
  }, [width, wallet]);
  return (
    <div className={styles.wallet}>
      <span>
        <div className={styles.balanceValue}>${balance}</div>
      </span>
      <span>
        <SvgIconPlus
          size={width < 430 ? 26 : 40}
          style={width < 430 ? { marginRight: "-20px", marginTop: "-1px" } : {}}
        />
      </span>
    </div>
  );
};

interface Balance {
  wallet: string;
}
