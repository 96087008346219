import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import styles from "./index.module.css";
import ButtonFly from "../../../../Button";
import { Link } from "react-router-dom";
import { LINKS } from "../../../../../utils/constants";

export default function OnDemandDeployments() {
  return (
    <Container fluid data-testid="ondemand-deployments">
      <Row className={styles.main}>
        <Col xs={12} md={9} lg={7}>
          <Row className="pb-3">
            <Col>
              <h4>On-Demand Deployments</h4>
            </Col>
          </Row>
          <Row>
            <Col
              md={10}
              className={styles.text}
            >
              Get dedicated GPU access powered by FlymyAI’s high-performance
              serving stack. Enjoy unlimited access, high reliability, and no
              limits.
            </Col>
          </Row>
          <Row>
            <Col>
              <Link
                to={LINKS.DISCORD}
                target="_blank"
              >
                <ButtonFly
                  type="run"
                  image={null}
                  style={{ padding: "8px 16px", height: "44px" }}
                >
                  Deployed to my account
                </ButtonFly>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
