export default function ExclamationMark() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="7"
        cy="7"
        r="6"
        stroke="black"
        stroke-width="1"
        fill="none"
      />
      <text
        x="50%"
        y="59%"
        dominant-baseline="middle"
        text-anchor="middle"
        font-family="Arial"
        font-weight="700"
        font-size="10px"
        fill="black"
      >
        !
      </text>
    </svg>
  );
}
