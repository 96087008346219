import { createTeam, getUser } from "../../api/users";
import { toastError } from "../../utils/toasts";

export const loginSuccess = (userData) => ({
  type: "LOGIN_SUCCESS",
  payload: userData,
});

export const updateRequestUser = (userData) => ({
  type: "UPDATE_USER",
  payload: userData,
});

export const logout = () => ({ type: "USER_LOGOUT" });

export const setActiveUser = (userData) => ({
  type: "SET_ACTIVE_USER",
  payload: userData,
});

export const setLastActiveUser = (userData) => ({
  type: "SET_LAST_ACTIVE_USER",
  payload: userData,
});
export const setLoadingUser = (payload) => ({
  type: "SET_LOADING_USER",
  payload: payload,
});

export const deleteActiveUser = (id) => ({
  type: "DELETE_ACTIVE_USER",
  payload: id,
});

export const switchActiveUser =
  (username, navigate, projectName = undefined) =>
  async (dispatch, getState) => {
    const urlProjectName = projectName ? `/${projectName}` : "";
    try {
      dispatch(setLoadingUser(true));
      const { user, activeUser } = getState().user;
      if (username === activeUser.username) {
        dispatch(setLoadingUser(false));
        return;
      } else if (username === user.username) {
        dispatch(setActiveUser(user));
        dispatch(setLastActiveUser(user));
        dispatch(setLoadingUser(false));
        navigate(`/${user.username}`);
      } else {
        const userData = await getUser(username);
        if (userData) {
          dispatch(setActiveUser(userData));
          dispatch(setLastActiveUser(userData));
          dispatch(setLoadingUser(false));
          navigate(`/${userData.username}${urlProjectName}`);
        } else {
          dispatch(setActiveUser(user));
          console.log("switch with error");
          toastError("User not found", Math.floor(Math.random() * 1000000));
          dispatch(setLoadingUser(false));
          navigate(`/${user.username}`);
        }
        dispatch(setLoadingUser(false));
      }
    } catch (error) {
      console.error("Error switching active user:", error);
      toastError("Error switching user", Math.floor(Math.random() * 1000000));
      dispatch(setLoadingUser(false));
      navigate(`/explore`);
    }
  };

export const createTeamAction = (teamData) => async (dispatch) => {
  try {
    const createdTeam = await createTeam(teamData);
    const updatedUser = await getUser(createdTeam.team_founder);
    const newActiveUser = await getUser(createdTeam.username);
    dispatch(loginSuccess(updatedUser));
    dispatch(switchActiveUser(newActiveUser.username));
  } catch (error) {
    toastError(
      `Error creating team: ${JSON.stringify(error)}`,
      Math.floor(Math.random() * 1000000)
    );
  }
};
