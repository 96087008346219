import React, { useState, useEffect, useRef, useCallback } from "react";
import { getProjects } from "../../api/projects";
import { useSelector } from "react-redux";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { HeaderSpacer } from "../Header/HeaderSpacer";
import { renderProjects } from "../../scripts/renderListAI";
import { CenteredContent } from "./ProjectMain/ProjectMainPage";
import useProjectPagination from "../../hooks/useProjectPagination.ts";
import {
  ACTIVE,
  NOT_ACTIVE,
  TITLE_SPLIT_GROUP,
} from "../../utils/constants.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import ScrollMenu from "../Explore/components/ScrollMenu/index.tsx";
import { getDemos } from "../../api/demos.js";
import ButtonFly from "../Button/index.jsx";
import MyProjectSVG from "./images/MyProjectSVG.js";
import styled from "styled-components";
import { useResize } from "../../hooks/useresize.ts";
import { toastError } from "../../utils/toasts.js";

const StyledLink = styled(Link)`
  color: black !important;
  text-decoration: underline !important;
`;

function ProjectList() {
  const { username: url } = useParams();
  const width = useResize();
  const size = { xs: 12, sm: 6, md: 6, lg: 4, xl: 4 };
  const username = useSelector(
    (state) =>
      state.user?.activeUser.find((user) => user.id === window.name)?.user
        .username
  );

  const [activeProjects, setActiveProjects] = useState([]);
  const [notActiveProjects, setNotActiveProjects] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [notActivePage, setNotActivePage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreNot, setHasMoreNot] = useState(true);

  const loadingRef = useRef(false);
  const lastUserName = useRef(username);
  const observer = useRef(null);
  const navigate = useNavigate();

  const { activeProjects: demos, loading: loadingDemos } =
    useProjectPagination(getDemos);

  useEffect(() => {
    setActiveProjects([]);
    setNotActiveProjects([]);
    setError(null);
    setLoading(false);
    setPage(1);
    setNotActivePage(1);
    setHasMore(true);
    setHasMoreNot(true);
  }, [username]);

  useEffect(() => {
    lastUserName.current = username;
  }, [username]);

  const fetchData = async (page, username = undefined) => {
    if (loadingRef.current || username !== lastUserName.current || !username)
      return;

    setLoading(true);
    loadingRef.current = true;

    if (url !== username) {
      toastError(
        `To get a list of ${url} projects, you need to switch to this user.`
      );
      navigate(`/${username}`);
    }

    try {
      if (hasMore) {
        const data = await getProjects(username, page, ACTIVE);
        setActiveProjects((prev) => {
          const newItems = data.results.filter(
            (item) => !prev.some((p) => p.id === item.id)
          );
          return [...prev, ...newItems];
        });

        setHasMore(Boolean(data.next));
      }
      if (hasMoreNot || !hasMore) {
        const data = await getProjects(username, notActivePage, NOT_ACTIVE);
        setNotActiveProjects((prev) => {
          const newItems = data.results.filter(
            (item) => !prev.some((p) => p.id === item.id)
          );
          return [...prev, ...newItems];
        });

        setHasMoreNot(Boolean(data.next));
      }
    } catch (error) {
      setError(error.detail);
    } finally {
      setLoading(false);
      loadingRef.current = false;
    }
  };

  useEffect(() => {
    if (username !== lastUserName.current) return;
    fetchData(page, username);
  }, [page, lastUserName.current]);

  useEffect(() => {
    if (username !== lastUserName.current) return;
    fetchData(notActivePage, username);
  }, [notActivePage, lastUserName.current]);

  const loadMoreProjects = useCallback(() => {
    if (hasMore && !loadingRef.current) {
      setPage((prev) => prev + 1);
    } else if (hasMoreNot && !loadingRef.current) {
      setNotActivePage((prev) => prev + 1);
    }
  }, [hasMore, hasMoreNot]);

  const lastProjectRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    if (node) observer.current.observe(node);
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMoreProjects();
      }
    }, options);

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [loadMoreProjects]);

  if (error) {
    return <div className="error">{error}</div>;
  }
  return (
    <Container
      fluid="lg"
      style={{ minHeight: "calc(100vh - 190px)", marginTop: "0px" }}
    >
      <HeaderSpacer />
      <Row>
        <Col
          // xs={12}
          // md={6}
          // lg={8}
          className="pb-4"
        >
          <h2>My Project</h2>
        </Col>
      </Row>
      <ScrollMenu
        projects={demos}
        isLoading={loadingDemos}
      />
      {!activeProjects.length &&
        !notActiveProjects.length &&
        !loading &&
        !loadingRef.current && (
          <Row className="mt-4">
            <Col style={{ display: "grid", alignItems: "center" }}>
              <div>
                <h4>No model yet...</h4>
                <p
                  style={{
                    fontSize: "18px",
                    lineHeight: "140%",
                    color: "#3B405F",
                  }}
                >
                  No models available yet. Choose a model from the public list
                  under or request a custom model through{" "}
                  <StyledLink to="https://tally.so/r/3NVg5N">
                    Contact us
                  </StyledLink>{" "}
                </p>
                <p style={{ paddingTop: "24px" }}>
                  <Link to="/explore">
                    <ButtonFly type="run">Explore models</ButtonFly>
                  </Link>
                </p>
              </div>
            </Col>
            {width > 992 && (
              <Col style={{ textAlign: "end" }}>
                <MyProjectSVG />
              </Col>
            )}
          </Row>
        )}
      <HeaderSpacer />
      {renderProjects(
        activeProjects,
        TITLE_SPLIT_GROUP.ACTIVE,
        lastProjectRef,
        size
      )}
      {renderProjects(
        notActiveProjects,
        TITLE_SPLIT_GROUP.NOT_ACTIVE,
        lastProjectRef,
        size
      )}
      {(loading || loadingRef.current) && (
        <CenteredContent>
          <Spinner
            animation="border"
            role="status"
            variant="secondary"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </CenteredContent>
      )}
    </Container>
  );
}

export default ProjectList;
