"use client";
import React from "react";

const MyProjectSVG = ({ style }) => (
  <svg
    width="499"
    height="359"
    viewBox="0 0 499 359"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6">
      <g clip-path="url(#clip0_2596_5520)">
        <path
          d="M162.268 96.3438L155.832 90.023L155.832 67.9005L119.145 67.9005"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M338.433 263.328L351.111 273.515L351.111 294.524L373.299 294.524"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M343.212 248.654C343.812 246.536 345.759 244.985 348.068 244.985C350.855 244.985 353.114 247.245 353.114 250.032C353.114 252.819 350.855 255.078 348.068 255.078C345.757 255.078 343.809 253.525 343.211 251.406L330.175 251.406L330.175 248.654L343.212 248.654Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M343.212 219.294C343.812 217.177 345.759 215.626 348.068 215.626C350.855 215.626 353.114 217.885 353.114 220.672C353.114 223.459 350.855 225.719 348.068 225.719C345.757 225.719 343.809 224.166 343.211 222.047L330.175 222.047L330.175 219.294L343.212 219.294Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M343.212 233.974C343.812 231.857 345.759 230.306 348.068 230.306C350.855 230.306 353.114 232.565 353.114 235.352C353.114 238.139 350.855 240.398 348.068 240.398C345.757 240.398 343.809 238.846 343.211 236.727L330.175 236.727L330.175 233.974L343.212 233.974Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M343.212 204.607C343.812 202.49 345.759 200.938 348.068 200.938C350.855 200.938 353.114 203.198 353.114 205.985C353.114 208.772 350.855 211.031 348.068 211.031C345.757 211.031 343.809 209.478 343.211 207.359L330.175 207.359L330.175 204.607L343.212 204.607Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M343.212 189.927C343.812 187.81 345.759 186.259 348.068 186.259C350.855 186.259 353.114 188.518 353.114 191.305C353.114 194.092 350.855 196.352 348.068 196.352C345.757 196.352 343.809 194.799 343.211 192.68L330.175 192.68L330.175 189.927L343.212 189.927Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M343.212 163.318C343.812 161.201 345.759 159.649 348.068 159.649C350.855 159.649 353.114 161.909 353.114 164.696C353.114 167.483 350.855 169.742 348.068 169.742C345.757 169.742 343.809 168.189 343.211 166.07L330.176 166.07L330.176 163.318L343.212 163.318Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M343.212 133.958C343.812 131.841 345.759 130.29 348.068 130.29C350.855 130.29 353.114 132.549 353.114 135.336C353.114 138.123 350.855 140.383 348.068 140.383C345.757 140.383 343.809 138.83 343.211 136.711L330.175 136.711L330.175 133.958L343.212 133.958Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M343.212 148.638C343.812 146.521 345.759 144.97 348.068 144.97C350.855 144.97 353.114 147.229 353.114 150.016C353.114 152.803 350.855 155.063 348.068 155.063C345.757 155.063 343.809 153.51 343.211 151.391L330.175 151.391L330.175 148.638L343.212 148.638Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M343.212 119.271C343.812 117.154 345.759 115.603 348.068 115.603C350.855 115.603 353.114 117.862 353.114 120.649C353.114 123.436 350.855 125.695 348.068 125.695C345.757 125.695 343.809 124.142 343.211 122.023L330.175 122.023L330.175 119.271L343.212 119.271Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M343.212 104.591C343.812 102.474 345.759 100.923 348.068 100.923C350.855 100.923 353.114 103.182 353.114 105.969C353.114 108.756 350.855 111.016 348.068 111.016C345.757 111.016 343.809 109.463 343.211 107.344L330.175 107.344L330.175 104.591L343.212 104.591Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M179.701 85.1451C181.819 84.546 183.371 82.5986 183.371 80.2886C183.371 77.5015 181.112 75.2422 178.325 75.2422C175.538 75.2422 173.278 77.5015 173.278 80.2886C173.278 82.5984 174.83 84.5458 176.948 85.145L176.948 98.1839L179.701 98.1839L179.701 85.1451Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M209.061 85.1451C211.179 84.546 212.731 82.5986 212.731 80.2886C212.731 77.5015 210.472 75.2422 207.685 75.2422C204.898 75.2422 202.639 77.5015 202.639 80.2886C202.639 82.5984 204.191 84.5458 206.309 85.145L206.309 98.1839L209.061 98.1839L209.061 85.1451Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M194.381 85.1451C196.499 84.546 198.051 82.5986 198.051 80.2886C198.051 77.5015 195.791 75.2422 193.004 75.2422C190.217 75.2422 187.958 77.5015 187.958 80.2886C187.958 82.5984 189.51 84.5458 191.628 85.145L191.628 98.1839L194.381 98.1839L194.381 85.1451Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M223.742 85.1451C225.86 84.546 227.412 82.5986 227.412 80.2886C227.412 77.5015 225.153 75.2422 222.366 75.2422C219.579 75.2422 217.319 77.5015 217.319 80.2886C217.319 82.5984 218.871 84.5458 220.989 85.145L220.989 98.1839L223.742 98.1839L223.742 85.1451Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M238.422 85.1451C240.541 84.546 242.093 82.5986 242.093 80.2886C242.093 77.5015 239.833 75.2422 237.046 75.2422C234.259 75.2422 232 77.5015 232 80.2886C232 82.5984 233.552 84.5458 235.67 85.145L235.67 98.1839L238.422 98.1839L238.422 85.1451Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M265.031 85.1451C267.149 84.546 268.701 82.5986 268.701 80.2886C268.701 77.5015 266.442 75.2422 263.655 75.2422C260.868 75.2422 258.608 77.5015 258.608 80.2886C258.608 82.5984 260.16 84.5458 262.278 85.145L262.278 98.1839L265.031 98.1839L265.031 85.1451Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M294.391 85.1451C296.509 84.546 298.062 82.5986 298.062 80.2886C298.062 77.5015 295.802 75.2422 293.015 75.2422C290.228 75.2422 287.969 77.5015 287.969 80.2886C287.969 82.5984 289.521 84.5458 291.639 85.145L291.639 98.1839L294.391 98.1839L294.391 85.1451Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M279.712 85.1451C281.83 84.546 283.382 82.5986 283.382 80.2886C283.382 77.5015 281.122 75.2422 278.335 75.2422C275.548 75.2422 273.289 77.5015 273.289 80.2886C273.289 82.5984 274.841 84.5458 276.959 85.145L276.959 98.1839L279.712 98.1839L279.712 85.1451Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M309.072 85.1451C311.19 84.546 312.742 82.5986 312.742 80.2886C312.742 77.5015 310.483 75.2422 307.696 75.2422C304.909 75.2422 302.649 77.5015 302.649 80.2886C302.649 82.5984 304.201 84.5458 306.319 85.145L306.319 98.1839L309.072 98.1839L309.072 85.1451Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M323.753 85.1451C325.871 84.546 327.423 82.5986 327.423 80.2886C327.423 77.5015 325.164 75.2422 322.376 75.2422C319.589 75.2422 317.33 77.5015 317.33 80.2886C317.33 82.5984 318.882 84.5458 321 85.145L321 98.1839L323.753 98.1839L323.753 85.1451Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M179.701 270.855C181.819 271.454 183.371 273.401 183.371 275.711C183.371 278.498 181.112 280.758 178.325 280.758C175.538 280.758 173.278 278.498 173.278 275.711C173.278 273.402 174.83 271.454 176.948 270.855L176.948 257.816L179.701 257.816L179.701 270.855Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M209.061 270.855C211.179 271.454 212.731 273.401 212.731 275.711C212.731 278.498 210.472 280.758 207.685 280.758C204.898 280.758 202.639 278.498 202.639 275.711C202.639 273.402 204.191 271.454 206.309 270.855L206.309 257.816L209.061 257.816L209.061 270.855Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M194.381 270.855C196.499 271.454 198.051 273.401 198.051 275.711C198.051 278.498 195.791 280.758 193.004 280.758C190.217 280.758 187.958 278.498 187.958 275.711C187.958 273.402 189.51 271.454 191.628 270.855L191.628 257.816L194.381 257.816L194.381 270.855Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M223.742 270.855C225.86 271.454 227.412 273.401 227.412 275.711C227.412 278.498 225.153 280.758 222.366 280.758C219.579 280.758 217.319 278.498 217.319 275.711C217.319 273.402 218.871 271.454 220.989 270.855L220.989 257.816L223.742 257.816L223.742 270.855Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M238.422 270.855C240.541 271.454 242.093 273.401 242.093 275.711C242.093 278.498 239.833 280.758 237.046 280.758C234.259 280.758 232 278.498 232 275.711C232 273.402 233.552 271.454 235.67 270.855L235.67 257.816L238.422 257.816L238.422 270.855Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M265.031 270.855C267.149 271.454 268.701 273.401 268.701 275.711C268.701 278.498 266.442 280.758 263.655 280.758C260.868 280.758 258.608 278.498 258.608 275.711C258.608 273.402 260.16 271.454 262.278 270.855L262.278 257.816L265.031 257.816L265.031 270.855Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M294.391 270.855C296.509 271.454 298.062 273.401 298.062 275.711C298.062 278.498 295.802 280.758 293.015 280.758C290.228 280.758 287.969 278.498 287.969 275.711C287.969 273.402 289.521 271.454 291.639 270.855L291.639 257.816L294.391 257.816L294.391 270.855Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M279.712 270.855C281.83 271.454 283.382 273.401 283.382 275.711C283.382 278.498 281.122 280.758 278.335 280.758C275.548 280.758 273.289 278.498 273.289 275.711C273.289 273.402 274.841 271.454 276.959 270.855L276.959 257.816L279.712 257.816L279.712 270.855Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M309.072 270.855C311.19 271.454 312.742 273.401 312.742 275.711C312.742 278.498 310.483 280.758 307.696 280.758C304.909 280.758 302.649 278.498 302.649 275.711C302.649 273.402 304.201 271.454 306.319 270.855L306.319 257.816L309.072 257.816L309.072 270.855Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M323.753 270.855C325.871 271.454 327.423 273.401 327.423 275.711C327.423 278.498 325.164 280.758 322.376 280.758C319.589 280.758 317.33 278.498 317.33 275.711C317.33 273.402 318.882 271.454 321 270.855L321 257.816L323.753 257.816L323.753 270.855Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M157.49 248.654C156.891 246.536 154.944 244.985 152.634 244.985C149.847 244.985 147.588 247.245 147.588 250.032C147.588 252.819 149.847 255.078 152.634 255.078C154.945 255.078 156.893 253.525 157.491 251.406L170.527 251.406L170.527 248.654L157.49 248.654Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M157.49 219.294C156.891 217.177 154.944 215.626 152.634 215.626C149.847 215.626 147.588 217.885 147.588 220.672C147.588 223.459 149.847 225.719 152.634 225.719C154.945 225.719 156.893 224.166 157.491 222.047L170.527 222.047L170.527 219.294L157.49 219.294Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M157.49 233.974C156.891 231.857 154.944 230.306 152.634 230.306C149.847 230.306 147.588 232.565 147.588 235.352C147.588 238.139 149.847 240.398 152.634 240.398C154.945 240.398 156.893 238.846 157.491 236.727L170.527 236.727L170.527 233.974L157.49 233.974Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M157.49 204.607C156.891 202.49 154.944 200.938 152.634 200.938C149.847 200.938 147.588 203.198 147.588 205.985C147.588 208.772 149.847 211.031 152.634 211.031C154.945 211.031 156.893 209.478 157.491 207.359L170.527 207.359L170.527 204.607L157.49 204.607Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M157.49 189.927C156.891 187.81 154.944 186.259 152.634 186.259C149.847 186.259 147.588 188.518 147.588 191.305C147.588 194.092 149.847 196.352 152.634 196.352C154.945 196.352 156.893 194.799 157.491 192.68L170.527 192.68L170.527 189.927L157.49 189.927Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M157.49 163.318C156.891 161.201 154.944 159.649 152.634 159.649C149.847 159.649 147.588 161.909 147.588 164.696C147.588 167.483 149.847 169.742 152.634 169.742C154.945 169.742 156.893 168.189 157.491 166.07L170.527 166.07L170.527 163.318L157.49 163.318Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M157.49 133.958C156.891 131.841 154.944 130.29 152.634 130.29C149.847 130.29 147.588 132.549 147.588 135.336C147.588 138.123 149.847 140.383 152.634 140.383C154.945 140.383 156.893 138.83 157.491 136.711L170.527 136.711L170.527 133.958L157.49 133.958Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M157.49 148.638C156.891 146.521 154.944 144.97 152.634 144.97C149.847 144.97 147.588 147.229 147.588 150.016C147.588 152.803 149.847 155.063 152.634 155.063C154.945 155.063 156.893 153.51 157.491 151.391L170.527 151.391L170.527 148.638L157.49 148.638Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M157.49 119.271C156.891 117.154 154.944 115.603 152.634 115.603C149.847 115.603 147.588 117.862 147.588 120.649C147.588 123.436 149.847 125.695 152.634 125.695C154.945 125.695 156.893 124.142 157.491 122.023L170.527 122.023L170.527 119.271L157.49 119.271Z"
          fill="#7DA6D5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M157.491 104.591C156.892 102.474 154.945 100.923 152.635 100.923C149.848 100.923 147.589 103.182 147.589 105.969C147.589 108.756 149.848 111.016 152.635 111.016C154.946 111.016 156.894 109.463 157.492 107.344L170.528 107.344L170.528 104.591L157.491 104.591Z"
          fill="#7DA6D5"
        />
        <rect
          x="171.443"
          y="256.906"
          width="157.814"
          height="157.814"
          transform="rotate(-90 171.443 256.906)"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <rect
          x="198"
          y="230"
          width="102.763"
          height="98.1753"
          transform="rotate(-90 198 230)"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M268.27 140L253.78 168.831H272.93L223.566 217L238.057 188.169H221L268.27 140Z"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <circle
          cx="110.887"
          cy="67.8985"
          r="8.25773"
          transform="rotate(-90 110.887 67.8985)"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M177.111 44.8208C181.761 44.8208 185.531 41.1543 185.531 36.6315C185.531 32.1087 181.761 28.4423 177.111 28.4423C172.461 28.4423 168.691 32.1087 168.691 36.6315C168.691 41.1543 172.461 44.8208 177.111 44.8208ZM177.111 44.8208L177.111 54.2699L190.712 61.1993L190.712 70.6484"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M293.449 19.2498C298.024 19.2498 301.732 15.5566 301.732 11.0007C301.732 6.44481 298.024 2.75153 293.449 2.75153C288.874 2.75153 285.166 6.44481 285.166 11.0007C285.166 15.5566 288.874 19.2498 293.449 19.2498ZM293.449 19.2498L293.449 40.19L278.794 54.7847L278.794 70.6484"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M209.087 339.5C204.513 339.5 200.804 343.193 200.804 347.749C200.804 352.305 204.513 355.998 209.087 355.998C213.662 355.998 217.37 352.305 217.37 347.749C217.37 343.193 213.662 339.5 209.087 339.5ZM209.087 339.5L209.087 318.56L223.742 303.965L223.742 288.102"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M250.351 42.3862C254.911 42.3862 258.608 38.648 258.608 34.0367C258.608 29.4254 254.911 25.6873 250.351 25.6873C245.79 25.6873 242.093 29.4254 242.093 34.0367C242.093 38.648 245.79 42.3862 250.351 42.3862ZM250.351 42.3862L250.351 89.9141"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M250.351 303.464C254.911 303.464 258.608 307.215 258.608 311.842C258.608 316.469 254.911 320.22 250.351 320.22C245.79 320.22 242.093 316.469 242.093 311.842C242.093 307.215 245.79 303.464 250.351 303.464ZM250.351 303.464L250.351 266.086"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M321 304.929C325.561 304.929 329.258 308.556 329.258 313.031C329.258 317.505 325.561 321.132 321 321.132C316.439 321.132 312.742 317.505 312.742 313.031C312.742 308.556 316.439 304.929 321 304.929ZM321 304.929L321 288.102"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M339.188 52.1161C343.838 52.1161 347.608 48.4597 347.608 43.9493C347.608 39.4389 343.838 35.7825 339.188 35.7825C334.538 35.7825 330.769 39.4389 330.769 43.9493C330.769 48.4597 334.538 52.1161 339.188 52.1161ZM339.188 52.1161L339.188 60.597L314.577 60.597L314.577 70.6484"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M169.673 315.667C165.077 315.667 161.351 319.355 161.351 323.905C161.351 328.455 165.077 332.143 169.673 332.143C174.269 332.143 177.995 328.455 177.995 323.905C177.995 319.355 174.269 315.667 169.673 315.667ZM169.673 315.667L169.673 307.112L188.877 298.241L188.877 288.102"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <ellipse
          cx="383.851"
          cy="294.523"
          rx="8.25773"
          ry="8.71649"
          transform="rotate(90 383.851 294.523)"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M113.69 290.928C113.69 286.405 109.982 282.739 105.407 282.739C100.833 282.739 97.124 286.405 97.124 290.928C97.124 295.451 100.833 299.117 105.407 299.117C109.982 299.117 113.69 295.451 113.69 290.928ZM113.69 290.928L124.522 290.928L124.522 256.911L143 256.911"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M362.404 82.2722C362.404 86.6639 366.075 90.2241 370.604 90.2241C375.133 90.2241 378.805 86.6639 378.805 82.2722C378.805 77.8805 375.133 74.3203 370.604 74.3203C366.075 74.3203 362.404 77.8805 362.404 82.2722ZM362.404 82.2722L349.788 82.2722L338.434 90.8358"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M56.8326 265.133C56.8326 260.555 53.1176 256.844 48.5349 256.844C43.9523 256.844 40.2373 260.555 40.2373 265.133C40.2373 269.711 43.9523 273.422 48.5349 273.422C53.1176 273.422 56.8326 269.711 56.8326 265.133ZM56.8326 265.133L108.533 265.133L108.533 235.803L143 235.803"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M443.869 84.4451C443.869 89.0229 447.584 92.7339 452.166 92.7339C456.749 92.7339 460.464 89.0229 460.464 84.4451C460.464 79.8673 456.749 76.1562 452.166 76.1562C447.584 76.1562 443.869 79.8673 443.869 84.4451ZM443.869 84.4451L404.934 84.4451L373.02 113.775L357.701 113.775"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M69.6635 235.846C69.6635 231.307 65.9519 227.629 61.3733 227.629C56.7947 227.629 53.083 231.307 53.083 235.846C53.083 240.384 56.7947 244.063 61.3733 244.063C65.9519 244.063 69.6635 240.384 69.6635 235.846ZM69.6635 235.846L88.7949 235.846L97.7229 215.619L143.001 215.619"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M427.338 117.404C427.338 121.943 431.056 125.621 435.643 125.621C440.23 125.621 443.948 121.943 443.948 117.404C443.948 112.866 440.23 109.187 435.643 109.187C431.056 109.187 427.338 112.866 427.338 117.404ZM427.338 117.404L405.616 117.404L381.978 137.631L357.701 137.631"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M56.9363 204.23C56.9363 199.459 53.1981 195.592 48.5868 195.592C43.9755 195.592 40.2373 199.459 40.2373 204.23C40.2373 209 43.9755 212.867 48.5868 212.867C53.1981 212.867 56.9363 209 56.9363 204.23ZM56.9363 204.23L82.627 204.23L87.7651 193.599L143 193.599"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M442.997 149.61C442.997 154.202 446.702 157.924 451.272 157.924C455.842 157.924 459.547 154.202 459.547 149.61C459.547 145.019 455.842 141.297 451.272 141.297C446.702 141.297 442.997 145.019 442.997 149.61ZM442.997 149.61L395.894 149.61L384.436 162.4L357.702 162.4"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M18.2676 166.812C18.2676 162.35 14.5591 158.732 9.98439 158.732C5.4097 158.732 1.70117 162.35 1.70117 166.812C1.70117 171.275 5.4097 174.892 9.98439 174.892C14.5591 174.892 18.2676 171.275 18.2676 166.812ZM18.2676 166.812L92.1794 166.812L103.648 178L162.268 178"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M480.524 186.564C480.524 190.955 484.249 194.515 488.844 194.515C493.44 194.515 497.165 190.955 497.165 186.564C497.165 182.172 493.44 178.612 488.844 178.612C484.249 178.612 480.524 182.172 480.524 186.564ZM480.524 186.564L406.278 186.564L397.318 178L338.433 178"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M54.8835 104.613C54.8835 100.046 51.1938 96.3433 46.6424 96.3433C42.091 96.3433 38.4014 100.046 38.4014 104.613C38.4014 109.181 42.091 112.883 46.6424 112.883C51.1938 112.883 54.8835 109.181 54.8835 104.613ZM54.8835 104.613L122.714 104.613L122.714 144.055L142.999 144.055"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M451.257 245C451.257 249.551 454.962 253.241 459.531 253.241C464.1 253.241 467.805 249.551 467.805 245C467.805 240.449 464.1 236.759 459.531 236.759C454.962 236.759 451.257 240.449 451.257 245ZM451.257 245L385.705 245L385.705 218.375L357.702 218.375"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M76.2039 131.139C76.2039 126.617 72.4657 122.95 67.8544 122.95C63.2431 122.95 59.5049 126.617 59.5049 131.139C59.5049 135.662 63.2431 139.329 67.8544 139.329C72.4657 139.329 76.2039 135.662 76.2039 131.139ZM76.2039 131.139L110.244 131.139L110.244 165.156L143 165.156"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M213.741 32.8817C218.352 32.8817 222.091 29.218 222.091 24.6987C222.091 20.1793 218.352 16.5156 213.741 16.5156C209.13 16.5156 205.392 20.1793 205.392 24.6987C205.392 29.218 209.13 32.8817 213.741 32.8817ZM213.741 32.8817L213.741 56.1719L231.082 56.1719L231.082 70.6496"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M290.63 325.868C286.019 325.868 282.281 329.532 282.281 334.051C282.281 338.571 286.019 342.234 290.63 342.234C295.241 342.234 298.979 338.571 298.979 334.051C298.979 329.532 295.241 325.868 290.63 325.868ZM290.63 325.868L290.63 307.614L273.289 307.614L273.289 288.1"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M426.475 213.781C426.475 218.342 430.181 222.039 434.753 222.039C439.325 222.039 443.031 218.342 443.031 213.781C443.031 209.22 439.325 205.523 434.753 205.523C430.181 205.523 426.475 209.22 426.475 213.781ZM426.475 213.781L402.913 213.781L402.913 197.266L357.701 197.266"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M79.667 68.0185C84.2141 68.0185 87.9003 64.295 87.9003 59.7019C87.9003 55.1087 84.2141 51.3852 79.667 51.3852C75.1198 51.3852 71.4336 55.1087 71.4336 59.7019C71.4336 64.295 75.1198 68.0185 79.667 68.0185ZM79.667 68.0185L79.667 87.8506L137.934 87.8506L137.934 121.117L143.001 121.117"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
        <path
          d="M420.076 290.013C415.507 290.013 411.802 293.694 411.802 298.235C411.802 302.776 415.507 306.457 420.076 306.457C424.646 306.457 428.351 302.776 428.351 298.235C428.351 293.694 424.646 290.013 420.076 290.013ZM420.076 290.013L420.076 266.611L367.885 266.611L367.885 241.312L357.701 241.312"
          stroke="#7DA6D5"
          stroke-width="2.75258"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2596_5520">
        <rect
          width="359"
          height="499"
          fill="white"
          transform="translate(0 359) rotate(-90)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default MyProjectSVG;
