import React from "react";

const SvgIconPlus = ({ size = 40, ...props }) => {
  const calculatedSize = Math.max(28, size); // Минимальный размер 32px

  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={calculatedSize}
      height={calculatedSize}
      {...props}
    >
      <rect 
        width="40" 
        height="40" 
        fill="#0A0C19" 
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.25 19.75V15H20.75V19.75H25.5V21.25H20.75V26H19.25V21.25H14.5V19.75H19.25Z"
        fill="white"
      />
    </svg>
  );
};

export default SvgIconPlus;