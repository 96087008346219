import React from "react";
import { Link } from "react-router-dom";
import ButtonFly from "../Button";

export default function ButtonSuggestModel() {
  return (
    <Link
      to="https://discord.com/channels/1228406550801027102/1256322170569887864"
      target="_blank"
      rel="noopener noreferrer"
    >
      <ButtonFly
        type="run"
        style={{
          padding: "10px 16px",
          height: "40px",
          fontSize: "14px",
          borderRadius: "0px",
        }}
        image={""}
      >
        Suggest a model
      </ButtonFly>
    </Link>
  );
}
