import React, {useContext, useEffect, useState} from "react";
import BaseNode, {
    HandleActionsContainer,
    MinimizedCardBody,
    MinimizedCardHeader, MinimizedCardWrapper,
    StyledLeftHandle,
    StyledRightHandle,
} from "./BaseNode";
import {Button, Card, Grid, Modal, Spacer, Text, useModal, Input, Textarea, Checkbox} from "@nextui-org/react";
import FlowContext from "../FlowContext";
import {getHandleData, getMaxHandleWidth} from "../utils";
import MonacoEditor from "react-monaco-editor";
import {createManuallyIOField, updateIOField, updateNode, deleteIOField, getNode} from "../../../api/flow";
import {prepareNodeForApi} from "../Tree";
import {compareDims, compareFormats} from "./checks";
import {toastWarn} from "../../../utils/toasts";
import StyledSelect from "../styles/styles";
import {HANDLE_TYPES} from "../constants";
import CustomTestModal from "./TestProcessingNodeModal";


const ContentBody = ({data, nodes, openModal, nodeId, username, projectName, projectState}) => {
    const source_handles = data.source_handles || [];
    const target_handles = data.target_handles || [];
    const [selectedHandle, setSelectedHandle] = useState(null);
    const [maxHandleWidth, setMaxHandleWidth] = useState(50);

    useEffect(() => {
        if (target_handles.length > 0 || source_handles.length > 0) {
            const handles = target_handles.concat(source_handles);
            const maxWidth = getMaxHandleWidth(handles);
            setMaxHandleWidth(maxWidth);
        }
    }, [source_handles, target_handles]);

    useEffect(() => {
    }, [selectedHandle]);


    const openInputModal = () => {
        openModal(null, "target");
    };

    const openOutputModal = () => {
        openModal(null, "source");
    };


    const addNewSourceHandle = () => {
        const newObjExists = source_handles.find((obj) => obj.id === "new_source_handle");
        if (!newObjExists && ['ERROR', 'IDLE'].includes(projectState)) {
            const newObj = {
                id: "new_source_handle",
                label: "Connect field",
            };
            source_handles.push(newObj);
        }
    };

    const addNewTargetHandle = () => {
        const newObjExists = target_handles.find((obj) => obj.id === "new_target_handle");
        if (!newObjExists && ['ERROR', 'IDLE'].includes(projectState)) {
            const newObj = {
                id: "new_target_handle",
                label: "Connect field",
            };
            target_handles.push(newObj);
        }
    };

    addNewSourceHandle();
    addNewTargetHandle();

    const createIsValidConnection = (handleId, nodeId, handleType) => {
        return (connection) => {
            const newHandlesIds = ['new_source_handle', 'new_target_handle'];
            const {
                startNode,
                startHandle,
                endNode,
                endHandle
            } = getHandleData(nodes, handleId, nodeId, handleType, connection);
            if (startNode.id === endNode.id) {
                toastWarn('It\'s the same node', `sameNode-${startHandle.id}-${endHandle.id}`);
                return false;
            }
            if (newHandlesIds.includes(endHandle.id) && newHandlesIds.includes(startHandle.id)) {
                toastWarn("Please, first create the handle manually", `createHandleManually-${startHandle.id}-${endHandle.id}`);
                return false;
            }
            if (newHandlesIds.includes(startHandle.id) && ['AI', 'CUSTOM_PYTHON', 'INPUT'].includes(endNode.type) && endHandle.ai_format) {
                return true;
            }
            if (JSON.stringify(endHandle.dims) && JSON.stringify(startHandle.dims)) {
                if (!compareDims(startHandle.dims, endHandle.dims)) {
                    toastWarn('Dims do not match', `dimsDoNotMatch-${startHandle.id}-${endHandle.id}`);
                    return false;
                }
            }
            if (endHandle.ai_format && startHandle.ai_format) {
                if (!compareFormats(startHandle.ai_format, endHandle.ai_format)) {
                    toastWarn('Formats do not match', `formatsDoNotMatch-${startHandle.id}-${endHandle.id}`);
                    return false;
                }
            }
            return true;
        }
    }

    return (
        <>
            <MinimizedCardWrapper>
                <Card variant={"flat"} css={{overflow: "visible", $$cardColor: "$colors$accents2"}}>
                    <MinimizedCardHeader>Inputs</MinimizedCardHeader>
                    {target_handles &&
                        target_handles.map((target, index) => (
                            <MinimizedCardBody key={target.id || index}>
                                <Grid.Container justify="between">
                                    <Grid xs={6} style={{ width: maxHandleWidth }}>
                                        {target.label}
                                    </Grid>
                                    {target.id !== "new_target_handle" && ['ERROR', 'IDLE'].includes(projectState) && (
                                        <Grid xs={6}>
                                            <HandleActionsContainer>
                                                <Button size="xs" auto
                                                        onClick={() => {
                                                            setSelectedHandle(target);
                                                            openModal(target, "target");
                                                        }}>
                                                    edit
                                                </Button>
                                            </HandleActionsContainer>
                                        </Grid>
                                    )}
                                </Grid.Container>
                                <StyledLeftHandle
                                    id={target.id ? target.id.toString() : ''}
                                    type="target"
                                    position="left"
                                    optional={target.data?.optional?.toString()}
                                    isValidConnection={createIsValidConnection(target.id, nodeId, "target_handles")}
                                />
                            </MinimizedCardBody>
                        ))
                    }
                    {['ERROR', 'IDLE'].includes(projectState) &&
                        <>
                            <Spacer y={0.3}/>
                            <Button auto size="sm" bordered color="primary" onClick={openInputModal}>
                                Add input field
                            </Button>
                        </>
                    }
                </Card>
            </MinimizedCardWrapper>
            <Spacer y={1}/>
            <MinimizedCardWrapper>
                <Card variant={"flat"} css={{overflow: "visible", $$cardColor: "$colors$accents2"}}>
                    <MinimizedCardHeader>Outputs</MinimizedCardHeader>
                    {source_handles &&
                        source_handles.map((source, index) => (
                            <MinimizedCardBody key={source.id || index}>
                                <Grid.Container justify="between">
                                    <Grid xs={6} style={{ width: maxHandleWidth }}>
                                        {source.label}
                                    </Grid>
                                    {source.id !== "new_source_handle" && ['ERROR', 'IDLE'].includes(projectState) && (
                                        <Grid xs={6}>
                                            <HandleActionsContainer>
                                                <Button size="xs" auto
                                                        onClick={() => {
                                                            setSelectedHandle(source);
                                                            openModal(source, "source");
                                                        }}>
                                                    edit
                                                </Button>
                                            </HandleActionsContainer>
                                        </Grid>
                                    )}
                                </Grid.Container>
                                <StyledRightHandle
                                    id={source.id ? source.id.toString() : ''}
                                    type="source"
                                    position="right"
                                    optional={source.data?.optional?.toString()}
                                    isValidConnection={createIsValidConnection(source.id, nodeId, "source_handles")}
                                />
                            </MinimizedCardBody>
                        ))
                    }
                    {['ERROR', 'IDLE'].includes(projectState) &&
                        <>
                            <Spacer y={0.3}/>
                            <Button auto size="sm" bordered color="primary" onClick={openOutputModal}>
                                Add output field
                            </Button>
                        </>
                    }
                </Card>
            </MinimizedCardWrapper>
        </>
    );
};

const ProcessingNode = ({username, projectName, setExternalNodes, ...props}) => {
    const {nodes, edges, projectState} = useContext(FlowContext);
    const [code, setCode] = useState("");
    const [requirements, setRequirements] = useState("");
    const [handleLabel, setHandleLabel] = useState("");
    const [handleType, setHandleType] = useState("source");
    const codeEditorModal = useModal();
    const handleModal = useModal();
    let node = nodes.find((node) => node.id === props.id);
    const [dims, setDims] = useState();
    const [aiFormat, setAiFormat] = useState("3");
    const [selectedHandle, setSelectedHandle] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [nodeState, setNodeState] = useState(null);
    const [perfAnalyzerDims, setPerfAnalyzerDims] = useState("");
    const [isCustomTestModalOpen, setIsCustomTestModalOpen] = useState(false);
    const [isOptional, setIsOptional] = useState(true);

    useEffect(() => {
        let node = nodes.find((node) => node.id === props.id);
        setNodeState(node);
        setCode(node?.data.code);
        setRequirements(node?.data.requirements);
    }, [props.id, nodes]);

    const handleEditorChange = (newValue) => {
        setCode(newValue);
        if (nodeState) {
            setNodeState({...nodeState, data: {...nodeState.data, code: newValue}});
        }
    };

    const handleRequirementsChange = (value) => {
        setRequirements(value);
        if (nodeState) {
            setNodeState({...nodeState, data: {...nodeState.data, requirements: value}});
        }
    };

    const saveCode = async () => {
        const preparedNode = prepareNodeForApi({...node, data: {...node.data, code, requirements}});
        await updateNode(preparedNode, username, projectName);
        codeEditorModal.setVisible(false);
        const tempNode = await getNode(preparedNode.id, username, projectName);
        await setExternalNodes(tempNode, tempNode.id);
    };

    const deleteHandle = async () => {
        if (selectedHandle) {
            const handleTypeKey = handleType === 'source' ? 'source_handles' : 'target_handles';
            const handleIndex = node.data[handleTypeKey].findIndex(
                (handle) => handle.id === selectedHandle.id
            );
            if (handleIndex !== -1) {
                node.data[handleTypeKey].splice(handleIndex, 1);
                await deleteIOField(selectedHandle.id, node.id, username, projectName);
            }
            handleModal.setVisible(false);
            setHandleLabel("");
            setDims("");
            setAiFormat("");
        }
    };

    const isConnectedHandle = (handleId, edges) => {
        if (handleId) {
            return edges.some(edge => edge.sourceHandle.toString() === handleId.toString() || edge.targetHandle.toString() === handleId.toString());
        }
        return false;
    };


    const openModal = (handle, type) => {
        if (handle) {
            console.log(handle)
            const perfDims = handle.data.perf_analyzer_dims;
            setHandleLabel(handle.label);
            setDims(handle.data.shape);
            setAiFormat(handle.data.type);
            setPerfAnalyzerDims(perfDims?.toString())
            setSelectedHandle(handle);
            setIsOptional(handle?.data?.optional ?? true);
        } else {
            setHandleLabel("");
            setDims("");
            setAiFormat("3");
            setSelectedHandle(null);
            setPerfAnalyzerDims("")
        }
        const isConnected = isConnectedHandle(handle?.id, edges);
        setIsConnected(isConnected);
        setHandleType(type);
        handleModal.setVisible(true);
    };

    const addHandle = async () => {
        if (handleLabel && dims && aiFormat) {
            let dimsList = dims;
            let perfAnalyzerDimsList = perfAnalyzerDims;
            if (typeof dims === "string") {
                dimsList = dims.split(',').map(item => {
                    const number = Number(item);
                    return isNaN(number) ? item : number;
                });
            }
            if (typeof perfAnalyzerDims === "string") {
                perfAnalyzerDimsList = perfAnalyzerDims.split(',').map(item => {
                    const number = Number(item);
                    return isNaN(number) ? item : number;
                });
            }
            let fieldType
            if (handleType === 'source') {
                fieldType = 'output'
            } else {
                fieldType = 'input'
            }
            const newHandle = {
                label: handleLabel,
                data: {
                    shape: dimsList,
                    type: parseInt(aiFormat),
                    perf_analyzer_dims: perfAnalyzerDimsList,
                    optional: fieldType === 'input' ? isOptional : false,
                },
                is_target: handleType === 'target',
            };
            if (selectedHandle) {
                const handleTypeKey = handleType === 'source' ? 'source_handles' : 'target_handles';
                const handleIndex = node.data[handleTypeKey].findIndex(
                    (handle) => handle.id === selectedHandle.id
                );
                if (handleIndex !== -1) {
                    node.data[handleTypeKey][handleIndex] = newHandle;
                    await updateIOField(newHandle, selectedHandle.id, node.id, username, projectName);
                }
            } else {
                await createManuallyIOField(newHandle, node.id, username, projectName, fieldType);
            }
            handleModal.setVisible(false);
            setHandleLabel("");
            setDims("");
            setAiFormat("");
            setPerfAnalyzerDims("")
            const tempNode = await getNode(node.id, username, projectName);
            await setExternalNodes(tempNode, tempNode.id);
        }
    };
    return (
        <>
            <CustomTestModal
                isOpen={isCustomTestModalOpen}
                onClose={() => setIsCustomTestModalOpen(false)}
                node={node}
                username={username}
                projectName={projectName}
            />
            <Modal
                open={codeEditorModal.visible}
                onClose={() => codeEditorModal.setVisible(false)}
                height="70vh"
                width="90vw"
            >
                <Modal.Header>
                    <Text size={18}>Code Editor</Text>
                </Modal.Header>
                <Modal.Body>
                    <div style={{height: "60vh", width: "80vw"}}>
                        <MonacoEditor
                            language="python"
                            theme="vs"
                            width="100%"
                            height="100%"
                            value={code}
                            options={{selectOnLineNumbers: true}}
                            onChange={handleEditorChange}
                        />
                    </div>
                    <div style={{margin: "10px 0"}}>
                        <Textarea
                            width="100%"
                            label="Requirements"
                            placeholder="Enter python requirements (e.g., numpy==1.21.2). We use python 3.9."
                            value={requirements || ""}
                            onChange={(event) => handleRequirementsChange(event.target.value)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setIsCustomTestModalOpen(true)}>Test Node</Button>
                    <Button auto flat color="success" onClick={saveCode}>
                        Save
                    </Button>
                    <Button auto flat color="error" onClick={() => codeEditorModal.setVisible(false)}>
                        Close and don't save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                open={handleModal.visible}
                onClose={() => handleModal.setVisible(false)}
            >
                <Modal.Header>
                    <Text size={18}>{selectedHandle ? "Edit Handle" : "Add Handle"}</Text>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        label="Handle Label"
                        placeholder="Enter handle label"
                        value={handleLabel}
                        onChange={(e) => setHandleLabel(e.target.value)}
                        disabled={isConnected}
                    />
                    <Input
                        label="Dims"
                        placeholder="Enter dims"
                        value={dims}
                        onChange={(e) => {
                            setDims(e.target.value);
                        }}
                        disabled={isConnected}
                    />
                    <Text>AI Format</Text>
                    <StyledSelect value={aiFormat} disabled={isConnected} onChange={(e) => setAiFormat(e.target.value)}>
                        {HANDLE_TYPES.map(type => <option key={type.value} value={type.value}>{type.label}</option>)}
                    </StyledSelect>
                    {handleType === 'target' && (
                    <Checkbox
                        size={"sm"}
                        isSelected={isOptional}
                        onChange={setIsOptional}
                        isDisabled={isConnected}
                    >
                        Optional
                    </Checkbox>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {selectedHandle && (
                        <Button auto flat color="danger" onClick={deleteHandle} disabled={isConnected}>
                            Delete Handle
                        </Button>
                    )}
                    <Button auto flat color="success" onClick={addHandle} disabled={isConnected}>
                        Save
                    </Button>
                    <Button auto flat color="error" onClick={() => handleModal.setVisible(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>

            </Modal>

            <BaseNode
                id={props.id}
                label={props.data.label}
                type={props.type}
                data={props.data}
                openEditor={() => codeEditorModal.setVisible(true)}
                content={
                    <ContentBody
                        data={props.data}
                        nodes={nodes}
                        openModal={openModal}
                        selectedHandle={selectedHandle}
                        setSelectedHandle={setSelectedHandle}
                        nodeId={props.id}
                        projectState={projectState}
                    />
                }
            />
        </>
    );
};

export default ProcessingNode;