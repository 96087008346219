import React from "react";

const Logo = ({ maxWidth = "160px", marginTop="5px" }) => {
  const aspectRatio = 100.48 / 30.39;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      version="1.1"
      style={{
        marginTop: marginTop,
        width: "100%",
        maxWidth: maxWidth,
        height: `calc(${maxWidth} / ${aspectRatio})`,
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        imageRendering: "optimizeQuality",
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
      viewBox="0 0 100.48 30.39"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style type="text/css">
          {`
          .fil0 {fill:#0A0C19}
          .fil1 {fill:#1A67C1}
          .fil2 {fill:#FF9100}
          .fil3 {fill:yellow;fill-opacity:0.431373}
        `}
        </style>
      </defs>
      <g id="Слой_x0020_1">
        <g id="_2609838272224">
          <polygon
            className="fil0"
            points="0,0 19.44,0 19.44,13.53 26.55,13.53 26.55,18.04 30.46,11.28 23.95,0 29.15,0 33.06,6.77 36.96,0 42.17,0 31.76,18.04 14.93,18.04 14.93,0 12.32,4.51 4.51,4.51 4.51,9.02 9.72,9.02 7.11,13.53 4.51,13.53 4.51,18.04 0,18.04 "
          />
          <polygon
            className="fil0"
            points="42.17,0 49.29,0 51.54,3.91 53.8,0 66.12,0 70.02,6.77 73.93,0 79.14,0 68.72,18.04 63.51,18.04 67.42,11.28 60.91,0 60.91,18.04 56.4,18.04 56.4,4.51 51.54,12.93 46.68,4.51 46.68,18.04 42.17,18.04 "
          />
          <path
            className="fil1"
            d="M86.95 4.51l-2.6 4.51 2.6 0 0 -4.51zm9.02 13.53l4.51 0 0 -18.04 -4.51 0 0 18.04zm-14.23 -4.51l-2.6 4.51 -5.21 0c9.66,-16.73 4.89,-8.47 10.42,-18.04 2.37,0 4.74,0 7.11,0l0 18.04 -4.51 0 0 -4.51 -5.21 0z"
          />
          <g>
            <polygon
              className="fil2"
              points="79.13,18.05 73.94,18.05 78.46,10.21 66.12,22.55 71.31,22.55 66.79,30.39 "
            />
            <polygon
              className="fil3"
              points="78,18.51 73.13,18.51 76.27,13.07 67.25,22.09 72.12,22.09 68.98,27.53 "
            />
            <polygon
              className="fil3"
              points="76.87,18.98 72.32,18.98 74.08,15.93 68.38,21.62 72.94,21.62 71.17,24.67 "
            />
            <polygon
              className="fil3"
              points="75.74,19.45 71.5,19.45 71.89,18.77 69.51,21.15 73.75,21.15 73.36,21.83 "
            />
            <polygon
              className="fil3"
              points="74.6,19.92 71.41,19.92 70.65,20.68 73.83,20.68 "
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Logo;
