import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Text, Input } from "@nextui-org/react";
import { createTeamAction } from "../../../store/actions/user";
import styles from "../../Projects/ProjectMain/styles/Playground.module.css";

const CreateTeamModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [teamName, setTeamName] = useState("");

  const handleCreateTeam = async () => {
    if (teamName.trim() !== "") {
      await dispatch(createTeamAction({ username: teamName }));
      onClose();
      setTeamName("");
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <Modal.Header>
        <Text h3>Create Team</Text>
      </Modal.Header>
      <Modal.Body>
        <Input
          aria-label="Create Team"
          fullWidth
          placeholder="Enter team name"
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={onClose}
          className={styles.button}
        >
          Cancel
        </button>
        <button
          onClick={handleCreateTeam}
          className={styles.button}
        >
          Create
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateTeamModal;
