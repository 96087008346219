import React, {useCallback, useEffect, useRef} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toastError } from "../../utils/toasts";
import { oauthLogin } from "../../api/auth";
import { useDispatch } from "react-redux";
import { Text } from '@nextui-org/react';
import { loginSuccess, setActiveUser } from "../../store/actions/user";

export function OAuth2RedirectHandler() {
    let { service } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isRequestSent = useRef(false);

    const handleOAuthLogin = useCallback(async (provider, code, fieldName) => {
        dispatch({ type: 'LOGIN_REQUEST' });
        try {
            const data = { [fieldName]: code }
            const user = await oauthLogin(provider, data);
            dispatch({
                type: 'TOKEN_CREATED',
                payload: { accessToken: user.access, refreshToken: user.refresh },
            });
            dispatch(loginSuccess(user.user));
            dispatch(setActiveUser(user.user));
            navigate('/');
        }  catch (error) {
            if (error.non_field_errors) {
                toastError(error.non_field_errors[0], Math.floor(Math.random() * 1000000));
            }
            else if (error.response) {
                if (error.response.data.detail){
                    toastError(error.response.data.detail, Math.floor(Math.random() * 1000000));
                } else {
                    toastError(JSON.stringify(error.response.data), Math.floor(Math.random() * 1000000));
                }
            }
            dispatch({ type: 'LOGIN_FAILURE', payload: error.message });
            if (error.response && error.response.status === 403) {
                navigate('/welcome');
            } else {
                console.error(error);
                navigate('/login');
            }
        }
    }, [dispatch, navigate]);

    useEffect(() => {
        const fetchData = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            let provider;
            let code;
            let fieldName;

            switch (service) {
                case 'github':
                    provider = 'github';
                    code = urlParams.get('code');
                    fieldName = 'code';
                    break;
                case 'google':
                    provider = 'google';
                    code = urlParams.get('code');
                    fieldName = 'code';
                    break;
                default:
                    toastError('Unknown service', 'Unknown service');
                    navigate('/login');
                    return;
            }

            if (!isRequestSent.current && code !== null) {
                isRequestSent.current = true;
                await handleOAuthLogin(provider, code, fieldName);
                window.history.replaceState({}, document.title, window.location.pathname);
            }
        };

        fetchData();
    }, [service, navigate, handleOAuthLogin]);

    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Text h2 css={{
            textGradient: "45deg, $blue600 -20%, $pink600 50%",
            ":hover": { textGradient: "0deg, $blue600 0%, $pink600 100%" },
        }}
        >Authenticating via {service}...
        </Text>
    </div>
}