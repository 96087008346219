import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../api/users";
import {
  // deleteActiveUser,
  setActiveUser,
  updateRequestUser,
  logout,
} from "../../store/actions/user";
import NavBar from "./NavBar/index.tsx";

function Header() {
  const user = useSelector((state) => state.user.user);
  const activeUsers = useSelector((state) => state.user.activeUser);
  const lastActiveUser = useSelector((state) => state.user.lastUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (sessionStorage.getItem("root") == null) {
      const tabId = window.name || Math.random().toString(36).substring(2, 15);
      window.name = tabId;
      sessionStorage.setItem("tabId", tabId);
      if (lastActiveUser) {
        dispatch(setActiveUser(lastActiveUser));
      }
      if (lastActiveUser === undefined) {
        dispatch(logout());
      }
    }
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user) {
          const originalUser = await getUser(user.username);
          if (originalUser) {
            dispatch(updateRequestUser(originalUser));
          }
          const existingActiveUser = Array.isArray(activeUsers)
            ? activeUsers.find((e) => e.id === window.name)
            : null;
          if (!existingActiveUser && !lastActiveUser) {
            dispatch(setActiveUser(originalUser));
          }
        }
      } catch (error) {
        dispatch(logout());
        console.error("Error when receiving user data:", error);
      }
    };

    fetchData();
  // eslint-disable-next-line
  }, []);

  return (
    <header>
      <NavBar />
    </header>
  );
}

export default Header;
