import { useState, useEffect, useRef, useCallback } from "react";
import { ACTIVE, NOT_ACTIVE } from "../utils/constants";

/**
 * Custom hook for paginating projects based on their state.
 *
 * @param {FetchDataFunction} func - The function to fetch project.
 * @param {string} [username] - Optional username to filter projects by owner.
 * @returns {Object} An object containing:
 *   - activeProjects: Array of active projects.
 *   - notActiveProjects: Array of not active projects.
 *   - error: Error message if any error occurs during data fetching.
 *   - loading: Boolean indicating if data is currently being loaded.
 *   - lastProjectRef: Ref callback to be attached to the last project element for infinite scrolling.
 */

const useProjectPagination = (
  func: FetchDataFunction,
  username?: string
): object => {
  const [activeProjects, setActiveProjects] = useState<Project[]>([]);
  const [notActiveProjects, setNotActiveProjects] = useState<Project[]>([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [notActivePage, setNotActivePage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreNot, setHasMoreNot] = useState(true);

  const loadingRef = useRef(false);
  const lastUserName = useRef<string | undefined>(username);
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (lastUserName.current !== username) {
      setActiveProjects([]);
      setNotActiveProjects([]);
      setError(null);
      setLoading(false);
      setPage(1);
      setNotActivePage(1);
      setHasMore(true);
      setHasMoreNot(true);
      lastUserName.current = username;
    }
  }, [username]);

  const fetchData = useCallback(
    async (page: number, username?: string) => {
      if (loadingRef.current) return;

      setLoading(true);
      loadingRef.current = true;
      let data: FetchDataResult = defaultFetchDataResult;

      try {
        if (hasMore) {
          data = await func(
            ...(username ? [username, page, ACTIVE] : [page, ACTIVE])
          );
          setActiveProjects((prev) => {
            const newItems = data.results.filter(
              (item) => !prev.some((p) => p.id === item.id)
            );
            return [...prev, ...newItems];
          });
          setHasMore(Boolean(data.next));
        } else if (hasMoreNot) {
          data = await func(
            ...(username
              ? [username, notActivePage, NOT_ACTIVE]
              : [notActivePage, NOT_ACTIVE])
          );
          setNotActiveProjects((prev) => {
            const newItems = data.results.filter(
              (item) => !prev.some((p) => p.id === item.id)
            );
            return [...prev, ...newItems];
          });
          setHasMoreNot(Boolean(data.next));
        }
      } catch (error) {
        setError(error.detail);
        console.log("r", error.detail);
      } finally {
        setLoading(false);
        loadingRef.current = false;
      }
    },
    [func, username, hasMore, hasMoreNot, notActivePage]
  );

  useEffect(() => {
    fetchData(page, username);
  }, [page, username, fetchData]);

  useEffect(() => {
    fetchData(notActivePage, username);
  }, [notActivePage, username, fetchData]);

  useEffect(() => {
    if (!hasMore && hasMoreNot && notActivePage === 1) {
      fetchData(notActivePage, username);
    }
  }, [hasMore, hasMoreNot, notActivePage, fetchData, username]);

  const loadMoreProjects = useCallback(() => {
    if (loadingRef.current) return;
    if (hasMore) {
      setPage((prev) => prev + 1);
    } else if (hasMoreNot) {
      setNotActivePage((prev) => prev + 1);
    }
  }, [hasMore, hasMoreNot]);

  const lastProjectRef = useCallback(
    (node: HTMLElement | null) => {
      if (observer.current) observer.current.disconnect();
      if (node) {
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting) {
            loadMoreProjects();
          }
        });
        observer.current.observe(node);
      }
    },
    [loadMoreProjects]
  );

  return {
    activeProjects,
    notActiveProjects,
    error,
    loading,
    lastProjectRef,
  };
};

export default useProjectPagination;

interface FetchDataResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: Project[];
}

type FetchDataFunction = (...args: any) => Promise<FetchDataResult>;

const defaultFetchDataResult: FetchDataResult = {
  count: 0,
  next: null,
  previous: null,
  results: [],
};
